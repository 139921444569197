import { RiWhatsappFill } from 'react-icons/ri';
import { FaShare } from 'react-icons/fa';
import { bizNUM, url } from '../../utils/Utils'
import './ProductModal.css'
import { Link } from 'react-router-dom';

const ProductModal = ({ product: { name, price, picture, desc, sku, category, persons, mPrice, bPrice }, endPoint, onClose, contentLng: { whatsappDetails: { wspMsgStart, shareBtn, orderBtn, meal, s, m, b } } }) => {

  const defaultPic = picture ? `/products/${picture}` : 'default.png';
  const notIndividualPrices =
    <div className='notIndividualPrices'>
      <a className="product-price" href={`https://api.whatsapp.com/send?phone=${bizNUM}&text=${wspMsgStart} ${meal} ${name} ${s}.`}>₪{price}<br/>{s}</a>
      {persons >= 3 && <a className="product-price" href={`https://api.whatsapp.com/send?phone=${bizNUM}&text=${wspMsgStart} ${meal} ${name} ${m}.`}>₪{mPrice}<br/>{m}</a> }
      <a className="product-price" href={`https://api.whatsapp.com/send?phone=${bizNUM}&text=${wspMsgStart} ${meal} ${name} ${b}.`}>₪{bPrice}<br/>{b}</a>
    </div>

  return (
    <div className={`modal`} onClick={onClose}>
      <div className={`modal-content`} data-aos={`zoom-in`} data-aos-duration="800">
        <img src={`${endPoint}/logo.svg`} width={20} alt="" style={{ marginBottom: '10px' }} />
        <h2 className="product-title">{name}</h2>
        <p className="product-desc">{desc}</p>
        {persons > 1 ? notIndividualPrices : <p className="product-price">₪{price}</p>}
        <Link to={`/${category.slice(0, -3)}/${sku}`}>
          <img className='product-image' src={`${endPoint}${defaultPic}`} alt={`${name}`} style={{ borderRadius: '15px', marginBottom: '10px', height:'55vw',objectFit:"cover" }} />
        </Link>
        <div className="modal-buttons">
          <a href={`https://api.whatsapp.com/send?phone=${bizNUM}&text=${wspMsgStart} ${name}.`}>
            <RiWhatsappFill size={20} className='svgs' />{orderBtn}</a>
          <a href={`whatsapp://send?text=${name} - ${price}₪ - ${url}${category.slice(0, -3)}/${sku}`} data-action="share/whatsapp/share" rel="noreferrer" target="_blank" >
            <FaShare size={20} className='svgs' />{shareBtn}</a>
        </div>
      </div>
    </div>
  );
};

export default ProductModal