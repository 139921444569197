import React, { useState, useEffect } from 'react';
import { Title } from '../';
import './ProductsSlider.css';
import CategoryCard from '../CategoryCard/CategoryCard'; // Adjust the path as needed
import { Link } from 'react-router-dom';

const ProductsSlider = ({ contentLng, endPoint }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Get the top position of the 'categories' div
      const categoriesElement = document.querySelector('.categories');
      const categoriesTop = categoriesElement.getBoundingClientRect().top;
      // Calculate the threshold based on viewport height and navbar height
      // const threshold = window.innerHeight - 58;

      // Check if the top of the 'categories' div is within the viewport and below the threshold
      if (categoriesTop < 60 && window.scrollY > 0) {
        // setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='ProductsSlider'>
      <Title category={{ "name": contentLng.catTitle, "desc": contentLng.catDesc }} />
      <div className={`categories ${isVisible ? 'fixed' : ''}`} style={{ display: 'flex', width: '100%', overflowX: 'auto' }}>
        {contentLng.categories.map((category, i) => (
          <div key={i} style={{ flex: '0 0 auto', width: isVisible ? '25%' : '33%', marginRight: '10px' }}>
             <Link to={`/${category.name.slice(0, -3)}`}>
            <CategoryCard category={category} endPoint={endPoint} isVisible={isVisible} />
             </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductsSlider;
