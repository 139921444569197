import React from 'react';
import './CategoryCard.css';

const CategoryCard = ({ category: { name, image }, endPoint, isVisible }) => {
  return (
    <div className={`CategoryCard ${isVisible ? 'circle' : ''}`} style={{
      backgroundImage: `url(${endPoint}/categories/${image})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
    }}>
      <div style={{
        background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%)',
        paddingBottom: 5,
        bottom: 0,
        left: 0,
        position: 'absolute',
        right: 0,
        top: 'auto',
        overflow: 'hidden'
      }}>
        <strong style={{ color: '#fff', padding: '10px' }}>{name.substring(0, name.length - 2)}</strong>
      </div>
    </div>
  );
};

export default CategoryCard;
